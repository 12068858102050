<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-22 13:38:15
 * @ Description: Component displaying search and favourite professional service cards.
 -->

<template>
    <v-card
        height="245px"
        width="100%"
        max-width="280px"
        class="rounded-lg pa-3 mx-auto"
    >
        <v-row>
            <v-col
                cols="12"
                style="position: relative;"
            >
                <v-btn
                    icon
                    aria-label="notifications"
                    style="position: absolute; right: 12px;"
                    v-if="unreadMessages > 0"
                >
                    <v-badge
                        overlap
                        color="#FFA858"
                        :content="unreadMessages"
                    >
                        <v-icon
                            color="#2E567A"
                        >
                            {{ mdiBell }}
                        </v-icon>
                    </v-badge>
                </v-btn>
                <v-btn
                    icon
                    aria-label="notifications"
                    style="position: absolute; right: 12px;"
                    v-else-if="!unreadMessages"
                >
                    <v-icon
                        color="#2E567A"
                    >
                        {{ mdiBell }}
                    </v-icon>
                </v-btn>
                <div
                    style="display: flex; justify-content: center;"
                >
                    <v-avatar
                        size="64"
                    >
                        <v-img
                            :src="request.owner.avatar ? request.owner.avatar : request.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                            contain
                        />
                    </v-avatar>
                </div>
            </v-col>
            <v-col
                class="os-16-sb text-truncate text-center py-0"
                cols="12"
            >
                <span v-if="request.owner.company_id">{{ request.owner.company_name }}</span>
                <span v-else>{{ request.owner.first_name }} {{ request.owner.last_name }}</span>
            </v-col>
            <v-col
                cols="6"
            >
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    <span v-if="!request.accepted_date">Requested Date:</span>
                    <span v-else>Accepted Date:</span>
                </div>
                <div
                    class="os-12-sb"
                    style="color: #2E567A"
                >
                    <v-row>
                        <v-col
                            class="text-truncate"
                        >
                            <span v-if="!request.accepted_date">{{ request.created_at }}</span>
                            <span v-else>{{ request.accepted_date }}</span>
                        </v-col>
                    </v-row>
                </div>
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Status:
                </div>
                <div
                    class="os-12-sb"
                    :style="request.status.stage_id === 1 ? 'color: #707070;' : request.status.stage_id === 2 ? 'color: #FFA858;' : request.status.stage_id === 3 ? 'color: #08D500;' : request.status.id === 7 ? 'color: #32C5FF;' : 'color: #E02020;'"
                >
                    <v-row>
                        <v-col
                            class="text-truncate"
                        >
                            {{ request.status.name }}
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col
                cols="6"
            >
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Due Date:
                </div>
                <div
                    class="os-12-sb"
                    style="color: #2E567A"
                >
                    <v-row>
                        <v-col
                            class="text-truncate"
                        >
                            <span v-if="request.is_specific_date">{{ request.start_date }}</span>
                            <span v-else>-</span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="6"
                class="os-12-b text-truncate"
                style="color: #2E567A;"
            >
                <!-- <span v-if="item.interval.id < 4">{{ item.currency.symbol }}{{ item.rate }} per {{ item.interval.name }}</span>
                <span v-else>Quote Upon Request</span> -->
            </v-col>
            <v-col
                cols="6"
                class="text-right os-12-sb"
                style="color: #2E567A; text-decoration: underline; cursor: pointer;"
                @click="viewRequest"
            >
                <span>View Request</span>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
    import { mdiHeartOutline, mdiHeart, mdiMapMarkerOutline, mdiStarOutline, mdiBell } from '@mdi/js'

    export default {
        name: 'WorkProfessionalServiceGeneralRequestCardComponent',

        props: {
            request: {
                type: Object,
                required: true
            },

            service: {
                type: Object,
                required: true
            }
        },

        computed: {
            unreadMessages () {
                if (this.$store.state.user.userData.id === this.request.owner.id) return this.request.applicant_unread_messages
                else return this.request.owner_unread_messages
            }
        },

        data () {
            return {
                mdiHeartOutline,
                mdiHeart,
                mdiMapMarkerOutline,
                mdiStarOutline,
                mdiBell
            }
        },

        methods: {
            viewRequest () {
                this.$emit('viewRequest', this.request)
            }
        }
    }
</script>
<style scoped>
    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }

    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }
</style>
