<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-22 13:39:15
 * @ Description: Fixer's provided dashboard page in the user's professional service dashboard section of the app.
 -->

<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                style="position: relative;"
            >
                <!-- Filters -->
                <v-row
                    align="center"
                >
                    <v-col
                        class="flex-shrink-1 flex-grow-0 os-15-sb"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        Filter:
                    </v-col>
                    <v-col
                        class="flex-grow-1 flex-shrink-0"
                    >
                        <v-row>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="os-13-r"
                                    background-color="white"
                                    placeholder="Industry"
                                    hide-details
                                    :items="industries"
                                    return-object
                                    item-text="name"
                                    item-value="id"
                                    v-model="searchIndustry"
                                    clearable
                                    @click:clear="searchIndustry = { id: null }"
                                />
                            </v-col>
                            <!-- <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="os-13-r"
                                    background-color="white"
                                    placeholder="Job Title"
                                    hide-details
                                    return-object
                                    item-text="name"
                                    item-value="id"
                                    v-model="searchJobTitle"
                                    clearable
                                    @click:clear="searchJobTitle = { id: null }"
                                />
                            </v-col> -->
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="os-13-r"
                                    background-color="white"
                                    placeholder="Service Location"
                                    hide-details
                                    :items="serviceLocations"
                                    return-object
                                    item-text="name"
                                    item-value="id"
                                    v-model="searchServiceLocation"
                                    clearable
                                    @click:clear="clearServiceLocation"
                                />
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <general-google-autocomplete-address-component
                                    @googlePlaceSelected="googlePlaceSelected"
                                    @addressChanged="addressChanged"
                                    :address="address"
                                    :placeholder="locationPlaceholder"
                                    :hideDetails="true"
                                    :clearable="true"
                                    :disabled="!canEnterLocation"
                                    class="mt-n2"
                                />
                            </v-col>
                            <v-col
                                :cols="$vuetify.breakpoint.sm ? '4' : $vuetify.breakpoint.xs ? '12' : ''"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r"
                                    background-color="white"
                                    v-model="mainSearch"
                                    clearable
                                    @click:clear="clearMainSearch"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        class="text-right pt-0"
                    >
                        <v-btn
                            class="no-uppercase rounded-lg os-13-sb mr-1"
                            color="#2E567A"
                            text
                            @click="clearFilters"
                        >
                            Clear Filters
                        </v-btn>
                        <!-- Search Button -->
                        <v-btn
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            @click="filterServices"
                        >
                            <span v-if="!$store.state.general.customBreakpoints.xxs">Filter Services</span>
                            <v-icon
                                class="ml-1"
                            >
                                {{ mdiFilterPlusOutline }}
                            </v-icon>
                        </v-btn>
                    <!-- Search Button -->
                    </v-col>
                </v-row>
                <!-- Filters -->

                <!-- Card View -->
                <v-row
                    v-if="selectedView === 'card'"
                >
                    <!-- No Professional Services -->
                    <v-col
                        v-if="items.length === 0 && !isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        {{ noDataText }}
                    </v-col>
                    <!-- No Professional Services -->

                    <!-- Professional Services -->
                    <v-col
                        cols="12"
                        class="pt-0"
                        v-for="(item, index) in items"
                        :key="index"
                        v-else
                    >
                        <!-- Service Detail -->
                        <v-row
                            style="background-color: white; min-height: 89px;"
                            class="mt-8 mb-4 mx-1"
                            align="center"
                            justify="center"
                            v-if="item.request.length > 0"
                        >
                            <v-col
                                class="flex-shrink-1 flex-grow-0"
                            >
                                <div
                                    style="height: 65px; align-items: center; justify-content-center;"
                                >
                                    <v-avatar
                                        height="65px"
                                        width="65px"
                                        class="ml-3"
                                    >
                                        <v-img
                                            :src="item.image ? item.image : item.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                            contain
                                        />
                                    </v-avatar>
                                </div>
                            </v-col>
                            <v-col
                                class="flex-shrink-0 flex-grow-1"
                            >
                                <v-row
                                    align="center"
                                >
                                    <v-col
                                        class="flex-shrink-0 flex-grow-1"
                                        style="line-height: 1;"
                                    >
                                        <div
                                            class="os-17-sb px-3"
                                            style="display: flex; align-items: center;"
                                        >
                                            <div>
                                                <span>{{ item.name }}</span>
                                                <br />
                                                <span class="os-12-b" style="color: #2E567A;">
                                                    <span v-if="item.interval.id < 4">{{ item.currency.symbol }}{{ item.rate }} per {{ item.interval.name }}</span>
                                                    <span v-else>Quote Upon Request</span>
                                                </span>
                                                <br v-if="$vuetify.breakpoint.xs" />
                                                <span class="os-12-sb ml-sm-3" style="color: #2E567A;">
                                                    {{ item.work_location.name }}
                                                </span>
                                                <v-icon
                                                    size="20"
                                                    color="#2E567A"
                                                >
                                                    {{ mdiMapMarkerOutline }}
                                                </v-icon>
                                            </div>

                                            <!-- Desktop Request Search -->
                                            <div
                                                style="width: 275px; position: absolute; right: 24px;"
                                                v-if="$vuetify.breakpoint.mdAndUp"
                                            >
                                                <v-text-field
                                                    dense
                                                    hide-details
                                                    outlined
                                                    color="#2E567A"
                                                    placeholder="Search..."
                                                    class="os-13-r rounded-lg"
                                                    background-color="white"
                                                    style="width: 275px;"
                                                    v-model="item.requestSearch"
                                                    clearable
                                                    @click:clear="item.requestSearch = ''"
                                                    @input="filterRequests(item)"
                                                >
                                                    <template v-slot:append>
                                                        <v-icon
                                                            color="#C5CEE0"
                                                            class="ma-0"
                                                        >
                                                            {{ mdiMagnify }}
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                            <!-- Desktop Request Search -->
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <!-- Mobile Request Search -->
                            <v-col
                                cols="12"
                                v-if="$vuetify.breakpoint.smAndDown"
                                class="text-center"
                                style="max-width: 300px;"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r rounded-lg"
                                    background-color="white"
                                    style="width: 275px;"
                                    v-model="item.requestSearch"
                                    clearable
                                    @click:clear="item.requestSearch = ''"
                                    @input="filterRequests(item)"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <!-- Mobile Request Search -->
                        </v-row>
                        <!-- Service Detail -->

                        <!-- Service Requests -->
                        <v-row
                            v-if="item.filteredRequests.length > 0"
                        >
                            <v-col
                                cols="12"
                                md="4"
                                lg="3"
                                v-for="(request) in item.filteredRequests"
                                class="pa-lg-1 pa-xl-3"
                                :key="request.id"
                            >
                                <work-professional-service-general-request-card-component
                                    :request="request"
                                    :service="item"
                                    @viewRequest="viewRequest"
                                />
                            </v-col>
                        </v-row>

                        <!-- No Requests in Request Search -->
                        <v-col
                            cols="12"
                            class="os-12-r text-center"
                            style="color: rgba(0, 0, 0, 0.38);"
                            v-else-if="item.filteredRequests.length === 0 && item.request.length > 0"
                        >
                            {{ noResultsText(item.requestSearch) }}
                        </v-col>
                        <!-- No Requests in Request Search -->
                        <!-- Service Requests -->
                    </v-col>
                    <!-- Professional Services -->

                    <v-col
                        v-if="isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                        cols="12"
                    >
                        Loading professional services...
                        <br />
                        <br />
                        <v-progress-circular
                            indeterminate
                            color="#FFA858"
                        />
                    </v-col>
                </v-row>
                <!-- Card View -->

                <!-- List View -->
                <v-row
                    v-if="selectedView === 'list'"
                >
                    <!-- No Services with Requests -->
                    <v-col
                        v-if="items.length === 0 && !isLoading"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        No requests
                    </v-col>
                    <!-- No Services with Requests -->

                    <!-- Services with Requests -->
                    <v-col
                        cols="12"
                        class="pt-0"
                        v-for="(mainItem, index) in items"
                        :key="index"
                        v-else
                    >
                        <v-row
                            class="pt-8"
                        >
                            <!-- Service Detail -->
                            <v-col
                                class="flex-shrink-1 flex-grow-0 pr-0 ml-3"
                                style="background-color: white;"
                                v-if="mainItem.request.length > 0"
                            >
                                <div
                                    style="height: 65px; align-items: center;"
                                >
                                    <v-avatar
                                        height="65px"
                                        width="65px"
                                        class="ml-3"
                                    >
                                        <v-img
                                            :src="mainItem.image ? mainItem.image : mainItem.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                            contain
                                        />
                                    </v-avatar>
                                </div>
                            </v-col>
                            <v-col
                                class="pl-0 flex-grow-1 flex-shrink-0 mr-3"
                                style="background-color: white; line-height: 1; height: 89px; display: flex; position: realtive;"
                                v-if="mainItem.request.length > 0"
                            >
                                <div
                                    class="os-17-sb px-3"
                                    style="display: flex; align-items: center;"
                                >
                                    <div>
                                        <span>{{ mainItem.name }}</span>
                                        <br />
                                        <span class="os-12-b" style="color: #2E567A;">
                                            <span v-if="mainItem.interval.id < 4">{{ mainItem.currency.symbol }}{{ mainItem.rate }} per {{ mainItem.interval.name }}</span>
                                            <span v-else>Quote Upon Request</span>
                                        </span>
                                        <br v-if="$vuetify.breakpoint.xs" />
                                        <span class="os-12-sb ml-sm-3" style="color: #2E567A;">
                                            {{ mainItem.work_location.name }}
                                        </span>
                                        <v-icon
                                            size="20"
                                            color="#2E567A"
                                        >
                                            {{ mdiMapMarkerOutline }}
                                        </v-icon>
                                    </div>

                                    <div
                                        style="width: 275px; position: absolute; right: 24px;"
                                    >
                                        <v-text-field
                                            dense
                                            hide-details
                                            outlined
                                            color="#2E567A"
                                            placeholder="Search..."
                                            class="os-13-r rounded-lg"
                                            background-color="white"
                                            style="width: 275px;"
                                            v-model="mainItem.requestSearch"
                                            clearable
                                            @click:clear="mainItem.requestSearch = ''"
                                            @input="filterRequests(mainItem)"
                                        >
                                            <template v-slot:append>
                                                <v-icon
                                                    color="#C5CEE0"
                                                    class="ma-0"
                                                >
                                                    {{ mdiMagnify }}
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                    </div>
                                </div>
                            </v-col>
                            <!-- Service Detail -->

                            <!-- Requests -->
                            <v-col
                                cols="12"
                                class="pt-0 px-md-1 px-lg-3"
                                v-if="mainItem.filteredRequests.length > 0"
                            >
                                <v-data-table
                                    :headers="tableHeaders"
                                    :items="mainItem.filteredRequests"
                                    item-key="id"
                                    hide-default-footer
                                    disable-pagination
                                    :no-data-text="noResultsText(mainItem.requestSearch)"
                                >
                                    <template
                                        v-slot:[`item.owner`]="{ item }"
                                    >
                                        <span>
                                            <v-row
                                                align="center"
                                            >
                                                <v-col
                                                    class="flex-grow-0 flex-shrink-1"
                                                >
                                                    <v-avatar
                                                        height="46px"
                                                        width="46px"
                                                        style="cursor: pointer;"
                                                    >
                                                        <v-img
                                                            :src="item.owner.avatar ? item.owner.avatar : item.owner.company_id ? '/general/FixerCompanyIcon.png' : '/general/FixerIndividualIcon.png'"
                                                            contain
                                                        />
                                                    </v-avatar>
                                                </v-col>
                                                <v-col
                                                    class="flex-grow-1 flex-shrink-0 pa-0"
                                                >
                                                    <span v-if="item.owner.company_id">{{ item.owner.company_name }}</span>
                                                    <span v-else>{{ item.owner.first_name }} {{ item.owner.last_name }}</span>
                                                </v-col>
                                            </v-row>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.start_date`]="{ item }">
                                        <span
                                            v-if="item.is_specific_date"
                                        >
                                            {{ item.start_date }}
                                        </span>
                                        <span v-else>-</span>
                                    </template>
                                    <template v-slot:[`item.accepted_date`]="{ item }">
                                        <span
                                            v-if="item.accepted_date"
                                        >
                                            {{ item.accepted_date }}
                                        </span>
                                        <span v-else>-</span>
                                    </template>
                                    <template v-slot:[`item.status`]="{ item }">
                                        <span
                                            class="os-12-sb"
                                            :style="item.status.stage_id === 1 ? 'color: #707070;' : item.status.stage_id === 2 ? 'color: #FFA858;' : item.status.stage_id === 3 ? 'color: #08D500;' : item.status.id === 7 ? 'color: #32C5FF;' : 'color: #E02020;'"
                                        >
                                            {{ item.status.name }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.messages`]="{ item }">
                                        <span>
                                            <!-- {{ item.messageCount }} New Message<span v-if="item.messageCount !== 1">s</span> -->
                                            {{ item.owner_unread_messages }} New Message<span v-if="item.owner_unread_messages !== 1">s</span>
                                            <v-badge
                                                overlap
                                                dot
                                                color="#FFA858"
                                                bottom
                                                v-if="item.owner_unread_messages > 0"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                    style="opacity: 0.65;"
                                                    size="20"
                                                    class="ml-2"
                                                >
                                                    {{ mdiMessageProcessing }}
                                                </v-icon>
                                            </v-badge>
                                            <v-icon
                                                color="#2E567A"
                                                style="opacity: 0.65;"
                                                size="20"
                                                class="ml-2"
                                                v-else
                                            >
                                                {{ mdiMessageProcessing }}
                                            </v-icon>
                                        </span>
                                    </template>
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn
                                            icon
                                            @click="viewRequest(item)"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                                style="opacity: 0.65;"
                                                size="20"
                                            >
                                                {{ mdiArrowRightThinCircleOutline }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                            <!-- Requests -->

                            <!-- No Requests in Request Search -->
                            <v-col
                                cols="12"
                                class="os-12-r text-center"
                                style="color: rgba(0, 0, 0, 0.38);"
                                v-else-if="mainItem.filteredRequests.length === 0 && mainItem.request.length > 0"
                            >
                                {{ noResultsText(mainItem.requestSearch) }}
                            </v-col>
                            <!-- No Requests in Request Search -->
                        </v-row>
                    </v-col>
                    <!-- Services with Requests -->
                </v-row>
                <!-- List View -->
            </v-col>
        </v-row>

        <!-- Confirm Request Overlay -->
        <work-professional-service-request-confirm-request-overlay-component
            :showRequestOverlayBoolean="showRequestOverlayBoolean"
            :serviceToRequest="serviceToRequest"
            @toggleRequestOverlayComponent="toggleRequestOverlayComponent"
        />
        <!-- Confirm Request Overlay -->
    </div>
</template>
<script>
    import WorkProfessionalServiceRequestConfirmRequestOverlayComponent from '@/overlays/work/professionalService/request/WorkProfessionalServiceRequestConfirmRequestOverlayComponent.vue'
    import { mdiMessageProcessing, mdiMapMarkerOutline, mdiStarOutline, mdiFilterPlusOutline, mdiEyeOutline, mdiMagnify, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiHeartOutline, mdiTextBoxPlusOutline, mdiHeart } from '@mdi/js'
    import { LookupPreferenceController, LookupIndustryController, LookupJobTitleController, LookupJobController, LookupLocationController, WorkProfessionalServiceBoardController, WorkProfessionalServiceFavouriteController } from '@/logic/controller/index.controller.js'
    import GeneralGoogleAutocompleteAddressComponent from '@/components/general/google/GeneralGoogleAutocompleteAddressComponent.vue'
    import WorkProfessionalServiceGeneralRequestCardComponent from '@/components/work/professionalService/general/WorkProfessionalServiceGeneralRequestCardComponent.vue'

    export default {
        name: 'WorkProfessionalServiceDashboardGeneralProvidedPage',

        components: {
            WorkProfessionalServiceRequestConfirmRequestOverlayComponent,
            GeneralGoogleAutocompleteAddressComponent,
            WorkProfessionalServiceGeneralRequestCardComponent
        },

        props: {
            selectedView: {
                type: String,
                required: true
            },

            selectedNavItem: {
                type: String,
                required: true
            }
        },

        watch: {
            selectedNavItem: {
                async handler (value) {
                    let shouldRetrieve
                    if (value === 'Active Services' || value === 'Pending Services' || value === 'History') shouldRetrieve = true
                    else shouldRetrieve = false

                    if (shouldRetrieve) {
                        this.items = []
                        this.mainSearch = ''
                        this.lastPageLoaded = 0
                        this.totalPages = 0
                        this.isLoading = false
                        this.searchIndustry = {
                            id: null
                        }
                        this.searchJobTitle = {
                            id: null
                        }
                        this.searchServiceLocation = {
                            id: null
                        }
                        this.searchCountry = {
                            id: null
                        }
                        this.searchState = null
                        this.searchCity = null
                        this.address = {
                            address_line_1: null
                        }

                        this.getProfessionalServiceCategory()
                        await this.getProfessionalServices()
                    }
                }
            }
        },

        computed: {
            canEnterLocation () {
                if (this.searchServiceLocation) {
                    if (this.searchServiceLocation.id) {
                        if (this.searchServiceLocation.id !== 2) return true
                        else return false
                    } else return true
                } else return true
            },

            locationPlaceholder () {
                if (this.searchServiceLocation) {
                    if (this.searchServiceLocation.id) {
                        if (this.searchServiceLocation.id !== 2) return 'Location'
                        else return 'Select "Onsite" service location'
                    } else return 'Location'
                } else return 'Location'
            },

            tableHeaders () {
                if (this.category === 'pending') {
                    return [
                        {
                            text: 'Requested By',
                            value: 'owner'
                        },
                        {
                            text: 'Requested Date',
                            value: 'created_at'
                        },
                        {
                            text: 'Due Date',
                            value: 'start_date'
                        },
                        {
                            text: 'Status',
                            value: 'status'
                        },
                        {
                            text: 'Messages',
                            value: 'messages'
                        },
                        {
                            text: 'Action',
                            value: 'action'
                        }
                    ]
                } else return [
                    {
                        text: 'Requested By',
                        value: 'owner'
                    },
                    {
                        text: 'Accepted Date',
                        value: 'accepted_date'
                    },
                    {
                        text: 'Due Date',
                        value: 'start_date'
                    },
                    {
                        text: 'Status',
                        value: 'status'
                    },
                    {
                        text: 'Messages',
                        value: 'messages'
                    },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ]
            },

            noDataText () {
                return 'No ' + this.category + ' services'
            }
        },

        data () {
            return {
                items: [],
                mdiMessageProcessing,
                mdiMapMarkerOutline,
                mdiStarOutline,
                mdiFilterPlusOutline,
                mdiEyeOutline,
                mdiMagnify,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiHeartOutline,
                mdiTextBoxPlusOutline,
                mdiHeart,
                showRequestOverlayBoolean: false,
                serviceToRequest: {
                    currency: {},
                    interval: {}
                },
                lookupIndustryController: null,
                lookupJobTitleController: null,
                lookupJobController: null,
                lookupLocationController: null,
                workProfessionalServiceBoardController: null,
                workProfessionalServiceFavouriteController: null,
                industries: [],
                searchIndustry: {
                    id: null
                },
                searchJobTitle: {
                    id: null
                },
                searchServiceLocation: {
                    id: null
                },
                searchCountry: {
                    id: null
                },
                searchState: null,
                searchCity: null,
                countries: [],
                address: {
                    job_id: null,
                    user_id: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                category: null,
                search: '',
                mainSearch: '',
                lastPageLoaded: 0,
                totalPages: 0,
                isLoading: true,
                serviceLocations: []
            }
        },

        beforeMount () {
            this.lookupIndustryController = new LookupIndustryController()
            this.lookupJobTitleController = new LookupJobTitleController()
            this.lookupJobController = new LookupJobController()
            this.lookupLocationController = new LookupLocationController()
            this.lookupPreferenceController = new LookupPreferenceController()
            this.workProfessionalServiceBoardController = new WorkProfessionalServiceBoardController()
            this.workProfessionalServiceFavouriteController = new WorkProfessionalServiceFavouriteController()
        },

        async mounted () {
            this.getIndustries()
            this.getCountries()
            this.getProfessionalServiceCategory()
            this.getLocations()
            await this.getProfessionalServices()

            var self = this
        
            const professionalServiceContainer = document.getElementById('professionalServiceContainer')
            professionalServiceContainer.addEventListener('scroll', async () => {
                if (this.selectedView === 'card') {
                    if (professionalServiceContainer.offsetHeight + professionalServiceContainer.scrollTop + 500 >= professionalServiceContainer.scrollHeight) {
                        if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                            await this.getProfessionalServices()
                        }
                    }
                } else {
                    if (professionalServiceContainer.offsetHeight + professionalServiceContainer.scrollTop + 120 >= professionalServiceContainer.scrollHeight) {
                        if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                            await this.getProfessionalServices()
                        }
                    }
                }
            })
        },

        methods: {
            noResultsText (requestSearch) {
                return 'No results found for: "' + requestSearch + '"'
            },

            getProfessionalServiceCategory () {
                switch (this.selectedNavItem) {
                case 'Active Services':
                    this.category = 'active'
                    break;
                case 'Pending Services':
                    this.category = 'pending'
                    break;
                case 'History':
                    this.category = 'history'
                    break;
                }
            },

            async getIndustries () {
                try {
                    this.industries = await this.lookupIndustryController.get(true, false, false, false, false, false)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCountries () {
                try {
                    this.countries = await this.lookupLocationController.getCountries()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getLocations () {
                try {
                    this.serviceLocations = await this.lookupPreferenceController.getWorkLocations()

                    const anyLocationIndex = this.serviceLocations.findIndex(itemToFind => itemToFind.name === 'Any')
                    if (anyLocationIndex > -1) this.serviceLocations.splice(anyLocationIndex, 1)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async filterServices () {
                this.lastPageLoaded = 0
                this.items = []
                await this.getProfessionalServices()
            },

            filterRequests (item) {
                const itemIndex = this.items.findIndex(itemToFind => itemToFind.id === item.id)
                if (itemIndex > -1) {
                    if (item.requestSearch && item.requestSearch.replace(/\s/g, '') !== '') {
                        const filteredItems = item.request.filter(function (e) {
                            if (e.owner.company_id) return e.owner.company_name.toLowerCase().includes(item.requestSearch.toLowerCase())
                            else return (e.owner.first_name.toLowerCase().includes(item.requestSearch.toLowerCase()) || e.owner.last_name.toLowerCase().includes(item.requestSearch.toLowerCase())) 
                        })

                        this.items[itemIndex].filteredRequests = filteredItems
                    } else this.items[itemIndex].filteredRequests = this.items[itemIndex].request

                    this.$forceUpdate()
                }
            },

            async clearFilters () {
                this.lastPageLoaded = 0
                this.items = []
                this.searchIndustry = {
                    id: null
                }
                this.searchJobTitle = {
                    id: null
                }
                this.searchServiceLocation = {
                    id: null
                }
                this.searchCountry = {
                    id: null
                }
                this.searchState = null
                this.searchCity = null
                this.mainSearch = ''
                this.address = {
                    address_line_1: null
                }
                await this.getProfessionalServices()
            },

            clearServiceLocation () {
                this.searchServiceLocation = {
                    id: null
                }
                this.searchCountry = {
                    id: null
                }
                this.searchState = null
                this.searchCity = null

                this.address = {
                    address_line_1: null
                }
            },

            clearMainSearch () {
                this.mainSearch = ''
            },

            viewRequest (request) {
                this.$router.push(`/service/${request.service_id}/requested/${request.id}`)
            },

            async getProfessionalServices () {
                try {
                    this.isLoading = true

                    let query = {}

                    let industryID = null
                    if (this.searchIndustry) {
                        if (this.searchIndustry.id) {
                            industryID = this.searchIndustry.id
                            query.industryID = industryID
                        }
                    }

                    let jobTitleID = null
                    if (this.searchJobTitle) {
                        if (this.searchJobTitle.id) {
                            jobTitleID = this.searchJobTitle.id
                            query.jobTitleID = jobTitleID
                        }
                    }

                    let serviceLocationID = null
                    if (this.searchServiceLocation) {
                        if (this.searchServiceLocation.id) {
                            serviceLocationID = this.searchServiceLocation.id
                            query.serviceLocationID = serviceLocationID
                            if (serviceLocationID === 2) {
                                this.searchState = null
                                this.searchCity = null
                                this.searchCountry = { id: null }

                                this.address = {
                                    address_line_1: null
                                }
                            }
                        } else {
                            this.searchState = null
                            this.searchCity = null
                            this.searchCountry = { id: null }

                            this.address = {
                                address_line_1: null
                            }
                        }
                    } else {
                        this.searchState = null
                        this.searchCity = null
                        this.searchCountry = { id: null }

                        this.address = {
                            address_line_1: null
                        }
                    }

                    let countryID = null
                    if (this.searchCountry) {
                        if (this.searchCountry.id) {
                            countryID = this.searchCountry.id
                            query.countryID = countryID
                        }
                    }

                    if (this.searchState) query.state = this.searchState
                    if (this.searchCity) query.city = this.searchCity

                    // if (Object.keys(query).length > 0) {
                    //     let routeQuery = this.$route.query
                    //     if (Object.keys(routeQuery).length > 0) {
                    //         for(var prop in routeQuery) {
                    //             query[prop] = routeQuery[prop]
                    //         }
                    //     }
                    // } else {
                    //     query.view = this.$route.query.view
                    // }

                    // if (query != this.$route.query) this.$router.replace({ query })

                    this.lastPageLoaded = Number(this.lastPageLoaded) + 1

                    let recordsToRetrieve = 10

                    if (this.selectedView === 'card') recordsToRetrieve = 10
                    else recordsToRetrieve = 20

                    const response = await this.workProfessionalServiceBoardController.retrieve('provided', this.lastPageLoaded, recordsToRetrieve, this.mainSearch, this.category, industryID, jobTitleID, null, serviceLocationID, countryID, this.searchState, this.searchCity)

                    if (response && response.length > 0) {
                        this.totalPages = response[0].page_total
                        for (let index = 0; index < response.length; index++) {
                            const item = response[index]

                            if (item.created_at) {
                                const removeTime = item.created_at.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.created_at = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                            }

                            if (item.request && item.request.length > 0) {
                                for (let index = 0; index < item.request.length; index++) {
                                    const request = item.request[index]
                                    
                                    const removeTime = request.created_at.split(' ')[0]
                                    const convertDate = new Date(removeTime)
                                    const splitConvertedDate = convertDate.toString().split(' ')
                                    request.created_at = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]

                                    if (request.start_date) {
                                        const removeTime = request.start_date.split(' ')[0]
                                        const convertDate = new Date(removeTime)
                                        const splitConvertedDate = convertDate.toString().split(' ')
                                        request.start_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                                    }

                                    if (request.accepted_date) {
                                        const removeTime = request.accepted_date.split(' ')[0]
                                        const convertDate = new Date(removeTime)
                                        const splitConvertedDate = convertDate.toString().split(' ')
                                        request.accepted_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3]
                                    }
                                }
                            }

                            item.requestSearch = ''
                            item.filteredRequests = item.request

                            this.items.push(item)
                        }
                    }

                    this.isLoading = false

                    this.$nextTick(async () => {
                        const professionalServiceContainer = document.getElementById('professionalServiceContainer')
                        if (response && response.length > 0 && this.lastPageLoaded < this.totalPages && professionalServiceContainer.offsetHeight + professionalServiceContainer.scrollTop === professionalServiceContainer.scrollHeight) await this.getProfessionalServices()
                    })                
                } catch (error) {
                    this.isLoading = false

                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            googlePlaceSelected (place) {
                if (place) {
                    Object.assign(this.address, place)

                    this.searchState = place.state
                    this.searchCity = place.city

                    const countryIndex = this.countries.findIndex(country => country.iso_2 === place.country.country_code)
                    if (countryIndex > -1) this.searchCountry.id = this.countries[countryIndex].id
                } else {
                    this.searchState = null
                    this.searchCity = null
                    this.searchCountry = { id: null }

                    this.address = {
                        job_id: null,
                        user_id: null,
                        address_line_1: null,
                        google_places_id: null,
                        lat: null,
                        lng: null,
                        state: null,
                        city: null,
                        country: {},
                        timezone: null
                    }
                }
            },

            addressChanged (address) {
                this.address.address_line_1 = address
            },

            toggleRequestOverlayComponent (value, confirmRequest) {
                this.showRequestOverlayBoolean = value

                if (confirmRequest) this.requestService(this.serviceToRequest)
            },

            async favouriteProfessionalService (professionalService) {
                try {
                    await this.workProfessionalServiceFavouriteController.toggleFavourite(professionalService.id)

                    if (this.category === 'all') {
                        const professionalServiceIndex = this.items.findIndex(itemToFind => itemToFind.id === professionalService.id)

                        if (professionalServiceIndex > -1) {
                            if (this.items[professionalServiceIndex].is_favourite === 0) {
                                this.$store.commit('setSnackbar', {
                                    text: 'Added to favourites',
                                    show: true,
                                    color: '#069B34'
                                })

                                this.items[professionalServiceIndex].is_favourite = 1
                            } else if (this.items[professionalServiceIndex].is_favourite === 1) {
                                this.$store.commit('setSnackbar', {
                                    text: 'Removed from favourites',
                                    show: true,
                                    color: '#069B34'
                                })

                                this.items[professionalServiceIndex].is_favourite = 0
                            }
                        }
                    } else if (this.category === 'favourite') {
                        const professionalServiceIndex = this.items.findIndex(itemToFind => itemToFind.id === professionalService.id)
                        if (professionalServiceIndex > -1) this.items.splice(professionalServiceIndex, 1)

                        this.$store.commit('setSnackbar', {
                            text: 'Removed from favourites',
                            show: true,
                            color: '#069B34'
                        })
                    }

                    if (this.items.length === 0 && this.lastPageLoaded < this.totalPages) await this.getProfessionalServices()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            professionalServiceDetail (professionalService) {
                this.$router.push(`/job/${professionalService.job_id}/view`)
            },

            confirmRequestService (service) {
                this.serviceToRequest = service
                this.toggleRequestOverlayComponent(true)
            },

            requestService (service) {
                this.$router.push(`/service/${service.id}/request`)
            }
        }
    }
</script>
<style scoped>
    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }

    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
        max-width: 250px;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
